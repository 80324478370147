<template>
  <div class="promo-box text-caption" :class="[warehousePromo.view.cssClass]">
    <!-- <span class="box-label">{{ label }}</span> -->
    <div v-html="warehousePromo.view.header" class="promo-header"></div>
    <div v-html="warehousePromo.view.body" class="promo-body"></div>
    <div v-html="warehousePromo.view.footer" class="promo-footer"></div>
  </div>
</template>
<script>
export default {
  name: "ProductPromo",
  props: {
    label: { type: String, default: "Promo Pezzo" },
    warehousePromo: { type: Object, required: true }
  }
};
</script>
